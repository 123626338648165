import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
// import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
/*  import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
 */
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import  Slider  from "./components/Slider";
import "./App.css";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

/*   const mediumURL = process.env.REACT_APP_MEDIUM_PATH;

  const [profile, setProfile] = useState({
    name: 'Krishna Thotakura',
    profileImage: '',
    profileUrl: ''
  })
  
  const [blog, setBlog] = useState({
    item: [],
    isLoading: true,
    error: null
  })

  useEffect(() => {
    fetch(mediumURL)
    .then(res => res.json())
    .then(info => {
      const image = info.feed.image;
      const link = info.feed.link;
      const blogs = info.items;
      const posts = blogs.filter(post => post.categories.length > 0)

      setProfile(p => (...p, profileUrl: link, profileImage: image}))
      setBlog({item: posts, isLoading: false})
    })
    .catch(err => setBlog({error: err.message}))
  }, []); */

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      {/*  <Features data={landingPageData.Features} />  */}
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
      <About data={landingPageData.About} />
     {/* <Slider />
      
      
      <Testimonials data={landingPageData.Testimonials} />  
      <Team data={landingPageData.Team} /> 
      */}
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
